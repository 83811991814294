<template>
  <el-menu
    :collapse="isCollapse"
    :router="true"
    :default-active="$route.path"
    class="layout-menu"
    background-color="#597EF7"
    text-color="#fff"
    active-color="#FFFFFF"
    active-text-color="#597EF7"
  >
    <template v-for="menuItem in getMenu">
      <el-submenu
        :index="menuItem.path"
        v-if="menuItem.children"
        :key="menuItem.path"
      >
        <template slot="title">
          <i class="iconfont" :class="menuItem.icon" :style="isCollapse ? styleItem : {}"></i>
          <span slot="title">{{ menuItem.meta.title }}</span>
        </template>
        <template v-for="menuChild in menuItem.children">
          <el-menu-item
            :key="menuChild.path"
            :route="{ name: menuChild.name }"
            :index="menuChild.path"
            v-if="!menuChild.hidden"
            >{{ menuChild.meta.title }}
          </el-menu-item>
        </template>
      </el-submenu>
      <el-menu-item
        :index="menuItem.path"
        :route="{ name: menuItem.name }"
        v-else-if="!menuItem.hidden"
        :key="menuItem.path"
      >
        <i class="iconfont" :class="menuItem.icon" :style="isCollapse ? styleItem : {}"></i>
        <span slot="title">{{ menuItem.meta.title }}</span>
      </el-menu-item>
    </template>
  </el-menu>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Menu",
  props: ["module", "isCollapse"],
  computed: {
    ...mapState({
      menuList: (state) => state.common.menuList,
    }),
    getMenu() {
      console.log(this.$router.options.routes);
      console.log(this.module);
      console.log(this.menuList);
      // return this.$router.options.routes[1].children;
      return this.menuList;
      // this.menuList.find(item => {
      //   return item.path === this.module
      // })
    },
    haveChildMenu() {
      return (menuItem) => {
        return (
          menuItem.children.length &&
          menuItem.children.some((item) => item.type === "0")
        );
      };
    },
  },
  data(){
    return{
      styleItem: { position: 'absolute', top: '50%',left: '50%', transform: 'translate(-50%, -50%)' }
    }
  }
};
</script>

<style lang="scss">
.layout-menu {
  border-right: none;
}

.layout-menu .el-menu-item.is-active {
  background-color: #ffffff !important;
  font-weight: bold !important;
  color: #597ef7;

  i {
    color: #597ef7;
  }
}

.el-menu--vertical {
  .el-menu-item.is-active {
    background-color: #ffffff !important;
    font-weight: bold !important;
    color: #597ef7;

    i {
      color: #597ef7;
    }
  }
}

.layout-menu .el-menu-item,.layout-menu .el-submenu__title{
 font-size: 18px!important;
//  padding-left: 40px!important;
}
//.layout-menu .el-submenu .el-menu-item{
//  padding-left: 60px!important;
//}
.layout-menu .el-menu-item i,
.layout-menu .el-submenu__title i {
  margin-right: 10px;
  color: #fff;
  font-size: 15px;
}

.layout-menu.el-menu {
  background: none;
}
.layout-menu:not(.el-menu--collapse) {
  width: 206px;
  height: 100%;
}
</style>
