<template>
  <el-container class="container">
    <el-aside :width="isCollapse ? '3.33333vw' : '10.72917vw'" class="layout-aside">
      <div class="layout-title-wrap" v-if="!isCollapse" @click="handleToHome">
        <img class="logo-img" src="../assets/img/jyjy-logo.png" alt="">
        <p class="layout-title">建业教育知识库平台</p>
      </div>
      <div class="layout-title-wrap-small" v-else @click="handleToHome">
        <img class="logo-img-small" src="../assets/img/jyjy-logo.png" alt="">
      </div>
      <div class="menu-wrap" :style="`top: ${isCollapse ? '3.125vw' : '5.98958vw'}`">
        <Menu :isCollapse="isCollapse" :module="module"/>
      </div>
    </el-aside>
    <el-container :style="{width: isCollapse ? 'calc(100% - 3.33333vw)' : 'calc(100% - 10.72917vw)'}">
      <el-header class="layout-header">
        <Header :module="module" @collapse="getCollapse"/>
        <TagView :module="module" ref="tagView"/>
      </el-header>
      <el-main class="layout-main-wrap">
        <div class="layout-main">
          <div class="router-wrap">
            <Main></Main>
          </div>
        </div>
        <div class="copyright">
          <span>Copyright </span>
          <i class="iconfont iconcopyright"></i>
          <span> 2021 建业教育</span>
        </div>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import Header from "@/layout/Header.vue";
import Menu from "@/layout/Menu";
import TagView from "@/components/TagView";
import Main from "@/layout/Main.vue";
// import {mapState} from "vuex";

export default {
  provide(){
    return {
        tagClose :(name) => this.$refs.tagView.handleClose(name),
        getHeaderisCollapse : () => this.getHeaderisCollapse()
    }
  },
  data() {
    return {
      list: [],
      menu: [],
      inputValue: "",
      isCollapse: false,
      module: ''
    };
  },
  created() {
    this.getModule();
  },
  watch: {
    '$route'() {
      this.getModule()
    }
  },
  computed:{
    // ...mapState(['keepAlive']),
    key() {
      return this.$route.path
    }
  },
  methods: {
    handleToHome(){
      this.$router.push('/')
    },
    getModule() {
      console.log(this.$route.matched)
      if(this.$route.matched[0].name == 'index'){
        this.module = "/"
      } else {
        this.module = this.$route.matched[0].path
      }
    },
    //获取菜单伸缩值
    getHeaderisCollapse(){
      return this.isCollapse
    },
    // 获取子组件的值
    getCollapse(data) {
      this.isCollapse = data;
      this.$forceUpdate()
    },
  },
  components: {Menu, TagView, Header, Main}
};
</script>

<style scoped lang="scss">
.container {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;

  .layout-title-wrap {
    cursor: pointer;
    width: 206px;
    background: #3056D4;
    height: 96px;
    padding-top: 23px;

    .layout-title {
      font-size: 20px;
      font-weight: 500;
      color: #fff;
      line-height: 1.4;
      text-align: center;
    }

    .layout-title-info {
      font-size: 12px;
      color: #9BAFEE;
      letter-spacing: -1px;
      line-height: 1.4;
    }
  }
  .layout-title-wrap-small{
    cursor: pointer;
    background: #3C62DE;
    height: 60px;
    overflow: hidden;
  }

  .layout-aside {
    position: relative;
    overflow: hidden;
    transition: all 0.25s;

    .menu-wrap {
      position: absolute;
      //top: 120px;
      bottom: 0;
      left: 0;
      width: 100%;
      overflow-y: scroll;
      background: #597EF7;

      &::-webkit-scrollbar {
        display: none; /* Chrome Safari */
      }
    }
  }

  .layout-header {
    width: 100%;
    height: 110px!important;
    padding-left: 0;
    padding-right: 0;
  }
  .layout-main-wrap{
    width: 100%;
    height: calc(100% - 110px);
    padding: 0;
    position: relative;
    overflow: hidden;
    .copyright{
      position: absolute;
      left: 0;
      bottom: 10px;
      right: 0;
      height: 20px;
      text-align: center;
      i,span{
        font-size: 14px;
        color: #999;
        line-height: 20px;
      }
    }
    .layout-main{
      width: calc(100% - 10px);
      position: absolute;
      top: 0;
      left: 10px;
      right: 0;
      bottom: 40px;
      overflow: hidden;
      .router-wrap{
        width: 100%;
        background: #F5F6FA;
        // padding: 12px;
        height: 100%;
        box-sizing: border-box;
        overflow-x: hidden;
        overflow-y: scroll;
      }
    }
  }

}

//路由过渡动画
.fade-enter-active,
.fade-leave-active {
  transition: all 0.2s ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
.logo-img{
  display: block;
  width: 55px;
  margin: 0 auto 10px;
}
.logo-img-small{
  display: block;
  width: 55px;
  margin: 15px auto 0;
}
</style>
